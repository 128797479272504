SystemJS.config( {
	baseURL: '/',
	paths: {
		'npm:': 'App_Themes/v2/lib/',
		'v2/': 'App_Themes/v2/js',
		'css/': 'App_Themes/v2/css',
	},
	map: {
		v2: 'v2/',
		css: 'css/',
		'systemjs-plugin-css': 'npm:systemjs-plugin-css/css.js',
		jquery: 'npm:jquery/dist/jquery.min.js',
		'jquery.bbq': 'npm:jquery-bbq/jquery.ba-bbq.js',
		'js-cookie': 'npm:js-cookie/src/js.cookie.js',
		'jquery-ui': 'npm:jquery-ui/ui',
		'jquery-ui/widgets/datepicker': 'App_Themes/v2/js/wrappers/jquery-ui/widgets/datepicker.js',
		'jquery-validation': 'App_Themes/v2/js/wrappers/jquery-validation.js',
		knockout: 'npm:knockout/build/output/knockout-latest.js',
		urijs: 'npm:urijs/src', 
		'slick-carousel': 'npm:slick-carousel/slick/slick.js'
	},
	packages: {
		v2: {
			defaultExtension: 'js'
		},
		css: {
			defaultExtension: 'css'
		},
		loader: {
			defaultExtension: 'js'
		},
		'jquery-ui': {
			defaultExtension: 'js'
		},
		'npm:jquery-validation': {
			defaultExtension: 'js'
		},
		urijs: {
			main: 'URI.js',
			defaultExtension: 'js'
		}
	},
	meta: {
		'*.css': { loader: 'systemjs-plugin-css' },
		'jquery-ui/*': {
			format: 'amd',
			deps: [
				'jquery',
				'App_Themes/jquery-ui/jquery-ui.min.css',
				'App_Themes/v2/css/jquery-ui.css'
			]
		},
		'npm:jquery-ui/*': {
			format: 'amd',
			deps: [
				'jquery',
				'App_Themes/jquery-ui/jquery-ui.min.css',
				'App_Themes/v2/css/jquery-ui.css'
			]
		},
		'npm:jquery-validation/*': {
			format: 'amd',
			deps: [ 'jquery' ]
		}
	},
	transpiler: false
} );
